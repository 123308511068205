@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.login-bg-img {
  background: url("./assets/images/svgs/LoginBgImg.svg");
  background-size: cover;
}
body {
  @apply font-inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: rgba(217, 217, 217, 0.8); */
}
html {
  font-size: 16px !important;
}
.customScrollbar::-webkit-scrollbar {
  display: none;
}

.customScrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

input[type="radio"] {
  position: relative;
  width: 1rem;
  height: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #6e7c87;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition: border 0.3s ease;
}
input[type="radio"]:checked {
  border: 1px solid #4a07a0;
}
input[type="radio"]:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.5rem;
  height: 0.5rem;
  background-color: #4a07a0;
  border-radius: 50%;
}
